import React from 'react';

const SpeedIcon = ({ isActive = true, variant = 'light' }) => (
  <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2 0.299805L0.699951 19.4998L11.8 19.3998L1.09995 37.0998L28.3 13.2998L15.8 13.1998L24.2 0.599805L11.2 0.299805Z"
      fill={
        isActive
          ? '#EED444'
          : (variant === 'light' && '#5C5E7B') || (variant === 'dark' && '#898B9E')
      }
    />
    <path
      d="M11.2 0.4C9.59998 3.8 7.89999 7.1 6.29999 10.3C4.69999 13.5 2.99999 16.7 1.29999 19.9L0.699982 18.9C4.39998 18.8 8.09999 18.8 11.8 18.8C12.2 18.8 12.5 19.1 12.5 19.5C12.5 19.6 12.5 19.7 12.4 19.8C11.5 21.3 10.5 22.7 9.59998 24.2L6.79999 28.6C5.89999 30 4.9 31.5 4 33C3.1 34.5 2.19999 35.9 1.29999 37.4L0.899994 37.1C3.19999 35.1 5.39998 33.1 7.59998 31.1L14.3 25L21 18.9L27.8 12.9L28.3 14.3L15.8 14C15.3 14 15 13.6 15 13.2C15 13 15.1 12.9 15.1 12.7C16.5 10.6 17.9 8.5 19.4 6.4L23.7 0.2L24.2 1.1C19.9 0.9 15.6 0.7 11.2 0.4ZM11.1 0.2C15.3 0.1 19.7 0 24.2 0C24.5 0 24.8 0.2 24.8 0.6C24.8 0.7 24.8 0.8 24.7 0.9L20.6 7.3C19.3 9.4 17.9 11.5 16.5 13.7L15.8 12.4L28.3 12.5C28.7 12.5 29.1 12.9 29.1 13.3C29.1 13.5 29 13.8 28.8 13.9L21.9 19.8L15 25.7L8 31.5C5.7 33.4 3.39998 35.4 1.09998 37.4C0.999976 37.5 0.799988 37.5 0.799988 37.4C0.699988 37.3 0.699988 37.2 0.799988 37.1C1.69999 35.6 2.6 34.1 3.5 32.7C4.4 31.2 5.19998 29.7 6.09998 28.2L8.59998 23.7C9.49998 22.2 10.3 20.7 11.2 19.2L11.8 20.2C8.09999 20.3 4.39998 20.3 0.699982 20.4C0.299982 20.4 0 20.1 0 19.7C0 19.6 -2.44156e-05 19.5 0.0999756 19.4C1.89998 16.2 3.69998 12.9 5.59998 9.8C7.39998 6.4 9.29998 3.2 11.1 0.2Z"
      fill="#33355A"
    />
  </svg>
);

export default SpeedIcon;
